import React, { useState } from 'react'
import { PageTemplate } from '../templates/page'
import { list, total } from 'cart-localstorage'
import { Link } from 'gatsby'
import { loadStripe } from '@stripe/stripe-js'
import SiteMetadata from '../components/SiteMetadata'
import Button from '../components/shortcodes/Button'
import Cart from '../components/Cart'
import CheckoutPageStyles from '../styles/CheckoutPage.module.sass'

const meta = {
  title: "Checkout",
  subtitle: "Pay quickly and securely for your EV Charger. <br />After payment, we will contact you to arrange an installation date.",
  pageIdentifier: "checkout",
  pageClass: "hide-cart",
  showCta: false,
  showContactForm: false
}

const PaymentPage = () => {
  // Define states
  const [validCountySelected, setValidCountySelected] = useState(true)

  // Show error if cart is empty
  let cart = (typeof window !== 'undefined') ? list() : null
  let totalPrice = (typeof window !== 'undefined') ? total() : null

  // Disable 
  if (cart && cart.length === 0) {
    return (
      <PageTemplate 
        title={meta.title}
        subtitle={meta.subtitle}
        pageIdentifier={meta.pageIdentifier}
        pageClass={meta.pageClass}
        showCta={meta.showCta}
        showContactForm={meta.showContactForm}
      >
        <div className="has-text-centered">
          <div className="notification">
            You can't proceed with the Checkout because your cart is empty.
          </div>
          <Button url="/" text="Shop Now" />
        </div>
      </PageTemplate>
    )
  }

  // Get metadata
  const { installationCounties } = SiteMetadata()

  // Handle form submission
  async function processForm(event) {
    event.preventDefault()

    let data = {}
    data['cart'] = cart
    data['total'] = totalPrice

    let fields = event.target.querySelectorAll(
      '.field input, .field select'
    )

    for (let field of fields) {
      data[field.name] = field.value
    }

    const response = await fetch('/.netlify/functions/stripe-checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(res => res.json())

    const stripe = await loadStripe(response.publishableKey)

    const { error } = await stripe.redirectToCheckout({
      sessionId: response.sessionId,
    })

    if (error) {
      console.error(error)
    }
  }

  return (
    <PageTemplate 
        title={meta.title}
        subtitle={meta.subtitle}
        pageIdentifier={meta.pageIdentifier}
        pageClass={meta.pageClass}
        showCta={meta.showCta}
        showContactForm={meta.showContactForm}
    >
      <div className="has-text-centered">
        <div className={`notification ${CheckoutPageStyles.grantWarning || ''}`}>
          <strong>Please Note:</strong> If you wish to avail of the SEAI Grant, you must have a Letter of Offer before you do anything else. <Link to="/ev-charger-grants"><strong>Click here</strong></Link> for details and to apply now.
        </div>
      </div>
      <Cart />
      <form
        className={
          'columns is-multiline pay-form ' + (CheckoutPageStyles.form || '')
        }
        onSubmit={event => processForm(event)}
      >
        <div className="field column is-half">
          <label htmlFor="name" className="label">
            Full Name
          </label>
          <div className="control">
            <input
              className="input"
              type="text"
              name="name"
              placeholder=""
              required
            />
          </div>
        </div>
        <div className="field column is-half">
          <label htmlFor="phone" className="label">
            Phone No.
          </label>
          <div className="control">
            <input
              className="input"
              type="tel"
              name="phone"
              placeholder=""
              pattern="[0-9 +]*"
              required
            />
          </div>
        </div>
        <div className="field column is-half">
          <label htmlFor="company" className="label">
            Company <small className="tag is-primary has-text-black">optional</small>
          </label>
          <div className="control">
            <input className="input" type="text" name="company" placeholder="" />
          </div>
        </div>
        <div className="field column is-half">
          <label htmlFor="email" className="label">
            E-mail address
          </label>
          <div className="control">
            <input
              className="input"
              type="email"
              name="email"
              placeholder=""
              required
            />
          </div>
        </div>
        <div className="field column is-half">
          <label htmlFor="address" className="label">
            Installation Address
          </label>
          <div className="control">
            <input
              className="input"
              type="text"
              name="address"
              placeholder=""
              required
            />
          </div>
        </div>
        <div className="field column is-half">
          <label htmlFor="city" className="label">
            City or Town
          </label>
          <div className="control">
            <input
              className="input"
              type="text"
              name="city"
              placeholder=""
              required
            />
          </div>
        </div>
        <div className="field column is-half">
          <label htmlFor="eircode" className="label">
            Eircode
          </label>
          <div className="control">
            <input className="input" type="text" name="eircode" placeholder="" required />
          </div>
        </div>
        <div className="field column is-half">
          <label htmlFor="county" className="label">
            County
          </label>
          <div className="control">
            <div className="select">
              <select // eslint-disable-line jsx-a11y/no-onchange
                name="county" 
                required
                onChange={event => setValidCountySelected(event.target[event.target.selectedIndex].getAttribute('data-valid'))}
              >
                {
                  installationCounties.map(item => {
                    return (
                      <option key={item[0]} name={item[0]} data-valid={item[1]}>
                        {item[0]}
                      </option>
                    )
                  })
                }
              </select>
            </div>
          </div>
        </div>
        {
          (validCountySelected === "true" || validCountySelected === true)
          ? (
            <div className="column is-three-fifths is-offset-two-fifths">
              <div className={`notification is-primary is-light ${CheckoutPageStyles.totals || ''}`}>
                <p><strong>Total: <b>&euro;{totalPrice}</b></strong></p>

                <button className={`button is-primary column is-full ${CheckoutPageStyles.submit || ''}`}>
                  Pay Now
                </button>
              </div>
            </div>
          )
          : (
            <div className="column is-full">
              <div className="notification is-primary is-light has-text-centered">
                <p><strong>Please note:</strong> We do not carry out installations in other counties at this time, however we deliver nationwide. Please contact us for a quote for delivery.</p>

                <Button />
              </div>
            </div>
          )
        }
        
      </form>
    </PageTemplate>
  )
}

export default PaymentPage
